import {
  AutocompleteGoogleDS,
  ButtonDS,
  GlobalIcon,
  InputDS,
  PredictiveSearchDS,
  SelectDS,
  SingleSelect,
} from "get-life-storybook-ts";
import numberFormat from "../../utils/Utils";
import { useContext, useEffect, useState } from "react";
import {
  BeneficiaryFormI,
  BeneficiaryITContext,
} from "../../context/BeneficiaryIT.context";
import { DOMAIN } from "../../constants/Global";
import IdentificationModal from "./IdentificationModal";

const BeneficiaryForm = ({
  totalCapital,
  minPercentage,
  maxPercentage,
}: any) => {
  const {
    translate,
    currentBeneficiary,
    beneficiariesList,
    cityList,
    countryList,
    beneficiaryForm,
    updateBeneficiaryForm,
    beneficiaryFormError,
    clearBeneficiaryError,
    updateCurrentBeneficiaryCapital,
    isEditingCapital,
    setIsEditingCapital,
  } = useContext(BeneficiaryITContext);

  const [identificationModal, setIdentificationModal] = useState(false);

  const genderOptions = [
    {
      label: translate("beneficiaries.questions.male"),
      value: "Male",
    },
    {
      label: translate("beneficiaries.questions.female"),
      value: "Female",
    },
  ];

  const sameResidenceOptions = [
    {
      value: "yes",
      text: "Sì",
      label: translate("beneficiaries.questions.yes"),
    },
    {
      value: "no",
      text: "No",
      label: translate("beneficiaries.questions.no"),
    },
  ];

  const affinityRelationshipOptions = [
    {
      value: "Parentela / Affinità",
      text: "Parentela / Affinità",
      label: translate(
        "beneficiaries.questions.affinityRelationship.parentela"
      ),
    },
    {
      value: "Lavoro",
      text: "Lavoro",
      label: translate("beneficiaries.questions.affinityRelationship.lavoro"),
    },
    {
      value: "Altro",
      text: "Altro",
      label: translate("beneficiaries.questions.affinityRelationship.altro"),
    },
  ];

  const pepTypeList = [
    {
      value: "Presidente della Repubblica Italiana o di stato estero",
      text: "Presidente della Repubblica Italiana o di stato estero",
      label: "Presidente della Repubblica Italiana o di stato estero",
    },
    {
      value:
        "Presidente del Consiglio italiano o carica analoga in stato estero",
      text: "Presidente del Consiglio italiano o carica analoga in stato estero",
      label:
        "Presidente del Consiglio italiano o carica analoga in stato estero",
    },
    {
      value: "Ministro italiano o carica analoga in stato estero",
      text: "Ministro italiano o carica analoga in stato estero",
      label: "Ministro italiano o carica analoga in stato estero",
    },
    {
      value: "Vice-Ministro italiano o carica analoga in stato estero",
      text: "Vice-Ministro italiano o carica analoga in stato estero",
      label: "Vice-Ministro italiano o carica analoga in stato estero",
    },
    {
      value: "Sottosegretario italiano o carica analoga in stato estero",
      text: "Sottosegretario italiano o carica analoga in stato estero",
      label: "Sottosegretario italiano o carica analoga in stato estero",
    },
    {
      value: "Deputato italiano o carica analoga in stato estero",
      text: "Deputato italiano o carica analoga in stato estero",
      label: "Deputato italiano o carica analoga in stato estero",
    },
    {
      value: "Senatore italiano o carica analoga in stato estero",
      text: "Senatore italiano o carica analoga in stato estero",
      label: "Senatore italiano o carica analoga in stato estero",
    },
    {
      value: "Parlamentare europeo o cariche analoghe in stato estero",
      text: "Parlamentare europeo o cariche analoghe in stato estero",
      label: "Parlamentare europeo o cariche analoghe in stato estero",
    },
    {
      value: "Presidente di Regione o carica analoga in stato estero",
      text: "Presidente di Regione o carica analoga in stato estero",
      label: "Presidente di Regione o carica analoga in stato estero",
    },
    {
      value: "Assessore regionale o carica analoga in stato estero",
      text: "Assessore regionale o carica analoga in stato estero",
      label: "Assessore regionale o carica analoga in stato estero",
    },
    {
      value: "Consigliere regionale italiano o carica analoga in stato estero",
      text: "Consigliere regionale italiano o carica analoga in stato estero",
      label: "Consigliere regionale italiano o carica analoga in stato estero",
    },
    {
      value:
        "Sindaco di capoluogo di provincia o città metropolitana italiana o carica analoga in stato estero",
      text: "Sindaco di capoluogo di provincia o città metropolitana italiana o carica analoga in stato estero",
      label:
        "Sindaco di capoluogo di provincia o città metropolitana italiana o carica analoga in stato estero",
    },
    {
      value:
        "Sindaco di comune con popolazione non inferiore a 15.000 abitanti o cariche analoghe in stato estero",
      text: "Sindaco di comune con popolazione non inferiore a 15.000 abitanti o cariche analoghe in stato estero",
      label:
        "Sindaco di comune con popolazione non inferiore a 15.000 abitanti o cariche analoghe in stato estero",
    },
    {
      value:
        "Direttore generale di ASL e di azienda ospedaliera, di azienda ospedaliera universitaria e degli altri enti del servizio sanitario nazionale",
      text: "Direttore generale di ASL e di azienda ospedaliera, di azienda ospedaliera universitaria e degli altri enti del servizio sanitario nazionale",
      label:
        "Direttore generale di ASL e di azienda ospedaliera, di azienda ospedaliera universitaria e degli altri enti del servizio sanitario nazionale",
    },
    {
      value:
        "Membro degli organi direttivi centrali di partiti politici italiani o esteri",
      text: "Membro degli organi direttivi centrali di partiti politici italiani o esteri",
      label:
        "Membro degli organi direttivi centrali di partiti politici italiani o esteri",
    },
    {
      value:
        "Giudice della Corte Costituzionale o carica analoga in stato estero",
      text: "Giudice della Corte Costituzionale o carica analoga in stato estero",
      label:
        "Giudice della Corte Costituzionale o carica analoga in stato estero",
    },
    {
      value:
        "Magistrato della Corte di Cassazione o carica analoga in stato estero",
      text: "Magistrato della Corte di Cassazione o carica analoga in stato estero",
      label:
        "Magistrato della Corte di Cassazione o carica analoga in stato estero",
    },
    {
      value: "Magistrato della Corte di Conti o carica analoga in stato estero",
      text: "Magistrato della Corte di Conti o carica analoga in stato estero",
      label: "Magistrato della Corte di Conti o carica analoga in stato estero",
    },
    {
      value: "Consigliere di Stato o carica analoga in stato estero",
      text: "Consigliere di Stato o carica analoga in stato estero",
      label: "Consigliere di Stato o carica analoga in stato estero",
    },
    {
      value:
        "Componente del Consiglio di Giustizia Amministrativa per la Regione siciliana",
      text: "Componente del Consiglio di Giustizia Amministrativa per la Regione siciliana",
      label:
        "Componente del Consiglio di Giustizia Amministrativa per la Regione siciliana",
    },
    {
      value:
        "Membro degli organi direttivi delle banche centrali e delle autorità indipendenti",
      text: "Membro degli organi direttivi delle banche centrali e delle autorità indipendenti",
      label:
        "Membro degli organi direttivi delle banche centrali e delle autorità indipendenti",
    },
    {
      value: "Ambasciatore italiano o carica equivalente in stato estero",
      text: "Ambasciatore italiano o carica equivalente in stato estero",
      label: "Ambasciatore italiano o carica equivalente in stato estero",
    },
    {
      value:
        "Incaricato d'affari italiano o carica equivalente in stato estero",
      text: "Incaricato d'affari italiano o carica equivalente in stato estero",
      label:
        "Incaricato d'affari italiano o carica equivalente in stato estero",
    },
    {
      value:
        "Ufficiale di grado apicale delle forze armate italiane o carica equivalente in stato estero",
      text: "Ufficiale di grado apicale delle forze armate italiane o carica equivalente in stato estero",
      label:
        "Ufficiale di grado apicale delle forze armate italiane o carica equivalente in stato estero",
    },
    {
      value:
        "Componente degli organi di amministrazione, direzione o controllo di impresa controllata, anche indirettamente, dallo Stato italiano o estero",
      text: "Componente degli organi di amministrazione, direzione o controllo di impresa controllata, anche indirettamente, dallo Stato italiano o estero",
      label:
        "Componente degli organi di amministrazione, direzione o controllo di impresa controllata, anche indirettamente, dallo Stato italiano o estero",
    },
    {
      value:
        "Componente degli organi di amministrazione, direzione o controllo di impresa partecipata, in misura prevalente o totalitaria, dalla Regioni, da comuni capoluoghi di provincia e città metropolitane o da comuni con popolazione complessivamente non inferiore a 15.000 abitanti",
      text: "Componente degli organi di amministrazione, direzione o controllo di impresa partecipata, in misura prevalente o totalitaria, dalla Regioni, da comuni capoluoghi di provincia e città metropolitane o da comuni con popolazione complessivamente non inferiore a 15.000 abitanti",
      label:
        "Componente degli organi di amministrazione, direzione o controllo di impresa partecipata, in misura prevalente o totalitaria, dalla Regioni, da comuni capoluoghi di provincia e città metropolitane o da comuni con popolazione complessivamente non inferiore a 15.000 abitanti",
    },
    {
      value:
        "Direttore, Vicedirettore e membro dell'organo di gestione o soggetto svolgente funzioni equivalenti in organizzazioni internazionali",
      text: "Direttore, Vicedirettore e membro dell'organo di gestione o soggetto svolgente funzioni equivalenti in organizzazioni internazionali",
      label:
        "Direttore, Vicedirettore e membro dell'organo di gestione o soggetto svolgente funzioni equivalenti in organizzazioni internazionali",
    },
  ];

  const pepRelationList = [
    {
      value: "Soggetto stesso",
      text: "Soggetto stesso",
      label: "Soggetto stesso",
    },
    {
      value: "Genitore di",
      text: "Genitore di",
      label: "Genitore di",
    },
    {
      value: "Coniuge di",
      text: "Coniuge di",
      label: "Coniuge di",
    },
    {
      value:
        "Soggetto legato in unione civile/convivenza di fatto/istituti assimilabili con",
      text: "Soggetto legato in unione civile/convivenza di fatto/istituti assimilabili con",
      label:
        "Soggetto legato in unione civile/convivenza di fatto/istituti assimilabili con",
    },
    {
      value: "Figlio/a di",
      text: "Figlio/a di",
      label: "Figlio/a di",
    },
    {
      value: "Coniuge di figlio/a di",
      text: "Coniuge di figlio/a di",
      label: "Coniuge di figlio/a di",
    },
    {
      value:
        "Soggetto legato in unione civile/convivenza di fatto/istituti assimilabili con figlio/a di",
      text: "Soggetto legato in unione civile/convivenza di fatto/istituti assimilabili con figlio/a di",
      label:
        "Soggetto legato in unione civile/convivenza di fatto/istituti assimilabili con figlio/a di",
    },
    {
      value:
        "Titolarità effettiva congiunta di enti giuridici o di altro stretto rapporto di affari con",
      text: "Titolarità effettiva congiunta di enti giuridici o di altro stretto rapporto di affari con",
      label:
        "Titolarità effettiva congiunta di enti giuridici o di altro stretto rapporto di affari con",
    },
    {
      value:
        "Soggetto che detiene solo formalmente il controllo totalitario di un 'entità notoriamente costituita, di fatto, nell'interesse e a beneficio di",
      text: "Soggetto che detiene solo formalmente il controllo totalitario di un 'entità notoriamente costituita, di fatto, nell'interesse e a beneficio di",
      label:
        "Soggetto che detiene solo formalmente il controllo totalitario di un 'entità notoriamente costituita, di fatto, nell'interesse e a beneficio di",
    },
  ];

  const getPercentageCapital = (percentage: number) => {
    if (totalCapital) {
      const _capital = (totalCapital / 100) * percentage;
      return _capital;
    }
    return 0;
  };

  const beneficiaryLength = (() => {
    if (beneficiaryForm.beneficiaryId) {
      return (
        beneficiariesList.findIndex(
          (b) => b.id === beneficiaryForm.beneficiaryId
        ) + 1
      );
    }
    return beneficiariesList.length + 1;
  })();

  useEffect(() => {
    if (beneficiaryForm.beneficiaryId) {
      setIsEditingCapital(true);
    } else {
      setIsEditingCapital(false);
    }
  }, []);

  return isEditingCapital ? (
    <div className="py-[24px] flex flex-col gap-[8px] max-w-full">
      <div className="pt-[24px] flex flex-row items-center gap-[8px]">
        <GlobalIcon
          iconName="SimpleUserIcon"
          color="var(--primary-color)"
          size="XS"
        />
        <div className="flex-1">
          <div className="flex flex-row items-center flex-1">
            <div className="flex flex-row items-end gap-[16px] flex-1">
              <div className="flex flex-col">
                <span className="text-[#7B7B7B] BodyS font-medium text-[12px]">
                  {translate("questions.beneficiaries.beneficiary")}{" "}
                  {beneficiaryLength}
                </span>
                <span className="text-[#424242] BodyL font-medium">
                  {beneficiaryForm.name} {beneficiaryForm.lastName}
                </span>
              </div>
              {currentBeneficiary.percentage &&
              !isNaN(currentBeneficiary.percentage) ? (
                <p className="BodyL text-[var(--primary-color)] font-bold">
                  {`${currentBeneficiary.percentage}% = ${numberFormat(
                    currentBeneficiary.capital!,
                    "capital"
                  )}`}
                </p>
              ) : null}
            </div>
            <GlobalIcon iconName="ChevronRight" size="XS" color="#424242" />
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-[16px]">
        <InputDS
          label={translate("questions.beneficiares.assign.capital")}
          onChange={(event) => {
            let value = parseInt(event.target.value);
            if (value > maxPercentage) value = maxPercentage;
            updateCurrentBeneficiaryCapital({
              capital: getPercentageCapital(value),
              percentage: value,
            });
          }}
          type="number"
          value={(() => {
            if (currentBeneficiary.percentage) {
              const percentageInt = parseInt(
                currentBeneficiary.percentage.toString()
              );
              if (isNaN(percentageInt)) return "";
              return percentageInt;
            }
            return "";
          })()}
          onBlur={(event) => {
            let value = parseInt(event.target.value);
            if (value > maxPercentage) value = maxPercentage;
            if (value < minPercentage) value = minPercentage;
            updateCurrentBeneficiaryCapital({
              capital: getPercentageCapital(value),
              percentage: value,
            });
          }}
          icon="PercentageIcon"
          placeholder={translate(
            "questions.beneficiares.assign.capital.placeholder"
          )}
          helper={translate("questions.beneficiares.assign.capital.helper")}
          min={minPercentage}
          max={maxPercentage}
          id="percentage-input-ref"
        />
        <ButtonDS
          label={translate("questions.beneficiares.assign.capital.save")}
          buttonType="tertiary"
          rightIcon="ArrowRightIcon"
          className="h-fit md:mt-[32px] w-full md:w-fit"
          disabled={
            !currentBeneficiary.percentage ||
            isNaN(currentBeneficiary.percentage)
          }
          onClick={() => {
            updateBeneficiaryForm(
              "capitalPercentage",
              currentBeneficiary.percentage
            );
            setIsEditingCapital(false);
          }}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="py-[24px] flex flex-row items-center gap-[8px]">
        <GlobalIcon
          iconName="SimpleUserIcon"
          color="var(--primary-color)"
          size="XS"
        />
        <div className="flex-1">
          <div className="flex flex-row items-center flex-1">
            <div className="flex flex-row gap-[16px] flex-1">
              <p className="BodyL text-[#424242] font-medium">
                {`${translate(
                  "questions.beneficiaries.beneficiary"
                )} ${beneficiaryLength}`}
              </p>
              <p className="BodyL text-[var(--primary-color)] font-bold">
                {`${currentBeneficiary.percentage}% = ${numberFormat(
                  currentBeneficiary.capital!,
                  "capital"
                )}`}
              </p>
            </div>
            <GlobalIcon iconName="ChevronRight" size="XS" color="#424242" />
          </div>
        </div>
      </div>
      <p className="mb-[24px] BodyM font-medium text-[#424242]">
        {translate("beneficiaries.questions.personalData")}
      </p>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[24px] gap-y-[48px]">
        <InputDS
          label={translate("beneficiaries.questions.name.label")}
          placeholder={translate("beneficiaries.questions.name.placeholder")}
          value={beneficiaryForm.name}
          error={beneficiaryFormError.name}
          onBlur={() => clearBeneficiaryError("name")}
          onChange={(e) => updateBeneficiaryForm("name", e.target.value)}
        />
        <InputDS
          label={translate("beneficiaries.questions.lastname.label")}
          placeholder={translate(
            "beneficiaries.questions.lastname.placeholder"
          )}
          value={beneficiaryForm.lastName}
          error={beneficiaryFormError.lastName}
          onBlur={() => clearBeneficiaryError("lastName")}
          onChange={(e) => updateBeneficiaryForm("lastName", e.target.value)}
        />
        <div className="md:col-span-2">
          <InputDS
            label={translate("beneficiaries.questions.zipCode.label")}
            placeholder={translate(
              "beneficiaries.questions.zipCode.placeholder"
            )}
            value={beneficiaryForm.fiscalCode}
            error={beneficiaryFormError.fiscalCode}
            onBlur={() => clearBeneficiaryError("fiscalCode")}
            onChange={(e) =>
              updateBeneficiaryForm("fiscalCode", e.target.value)
            }
            helper={`Non sei in possesso del codice fiscale del beneficiario? Chiama il <a style='text-decoration: underline;' href='tel:02 8294 21 28'>02 8294 21 28</a> o scrivici una mail a <a style='text-decoration: underline;' href='mailto:supporto@life5.it'>supporto@life5.it</a>`}
          />
        </div>
        <SelectDS
          label={translate("beneficiaries.questions.gender.label")}
          placeholder={translate("beneficiaries.questions.selectOption")}
          options={genderOptions}
          value={beneficiaryForm.gender ?? ""}
          error={beneficiaryFormError.gender}
          onBlur={() => clearBeneficiaryError("gender")}
          onChange={(e) =>
            updateBeneficiaryForm(
              "gender",
              e.target.value as BeneficiaryFormI["gender"]
            )
          }
        />
        <InputDS
          label={translate("beneficiaries.questions.birthdate.label")}
          type="date"
          value={beneficiaryForm.dateOfBirth}
          error={beneficiaryFormError.dateOfBirth}
          onBlur={() => clearBeneficiaryError("dateOfBirth")}
          onChange={(e) => updateBeneficiaryForm("dateOfBirth", e.target.value)}
        />
        <InputDS
          label={translate("beneficiaries.questions.email.label")}
          placeholder={translate("beneficiaries.questions.email.placeholder")}
          value={beneficiaryForm.email}
          error={beneficiaryFormError.email}
          onBlur={() => clearBeneficiaryError("email")}
          onChange={(e) => updateBeneficiaryForm("email", e.target.value)}
        />
        <SelectDS
          label={translate("beneficiaries.questions.countryBirth.label")}
          placeholder={translate("beneficiaries.questions.selectOption")}
          options={countryList}
          value={beneficiaryForm.countryOfBirth ?? ""}
          error={beneficiaryFormError.countryOfBirth}
          onBlur={() => clearBeneficiaryError("countryOfBirth")}
          onChange={(e) =>
            updateBeneficiaryForm("countryOfBirth", e.target.value)
          }
        />
        {beneficiaryForm.countryOfBirth === "IT" ? (
          <PredictiveSearchDS
            label={translate("beneficiaries.questions.cityBirth.label")}
            placeholder={translate("beneficiaries.questions.selectOption")}
            options={cityList}
            value={beneficiaryForm.cityOfBirth ?? ""}
            error={beneficiaryFormError.cityOfBirth}
            onBlur={() => clearBeneficiaryError("cityOfBirth")}
            onChange={(value) => updateBeneficiaryForm("cityOfBirth", value)}
            sortOptionsByValue={true}
          />
        ) : (
          <InputDS
            label={translate("beneficiaries.questions.cityBirth.label")}
            placeholder={translate(
              "beneficiaries.questions.cityBirth.placeholder"
            )}
            value={beneficiaryForm.cityOfBirth}
            error={beneficiaryFormError.cityOfBirth}
            onBlur={() => clearBeneficiaryError("cityOfBirth")}
            onChange={(e) =>
              updateBeneficiaryForm("cityOfBirth", e.target.value)
            }
            disabled={!!!beneficiaryForm.countryOfBirth}
            helper={
              beneficiaryForm.countryOfBirth
                ? ""
                : translate("beneficiaries.questions.cityBirth.helper")
            }
          />
        )}
      </div>
      <p className="mb-[24px] BodyM font-medium text-[#424242] mt-[48px]">
        {translate("beneficiaries.questions.residentialAddress")}
      </p>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[24px] gap-y-[48px]">
        <div className="md:col-span-2">
          <SelectDS
            label={translate("beneficiaries.questions.residenceCountry.label")}
            placeholder={translate("beneficiaries.questions.selectOption")}
            options={countryList}
            value={beneficiaryForm.residentialAddress?.country ?? ""}
            error={beneficiaryFormError.residentialAddress?.country}
            onBlur={() =>
              clearBeneficiaryError("residentialAddress", "country")
            }
            onChange={(e) =>
              updateBeneficiaryForm("residentialAddress", {
                ...beneficiaryForm.residentialAddress,
                country: e.target.value,
                city: "",
              })
            }
          />
        </div>
        <AutocompleteGoogleDS
          domain={DOMAIN!}
          value={beneficiaryForm.residentialAddress?.streetName}
          setValue={(value: string) => {
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              streetName: value,
            });
            clearBeneficiaryError("residentialAddress", "streetName");
          }}
          onSelect={(place) => {
            const { direction } = place;
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              streetName: direction,
            });
            clearBeneficiaryError("residentialAddress", "streetName");
          }}
          label={translate("beneficiaries.questions.residenceStreet.label")}
          placeholder={translate(
            "beneficiaries.questions.residenceStreet.placeholder"
          )}
          error={beneficiaryFormError.residentialAddress?.streetName}
        />
        <InputDS
          label={translate("beneficiaries.questions.residenceStreetNo.label")}
          placeholder={translate(
            "beneficiaries.questions.residenceStreetNo.placeholder"
          )}
          value={beneficiaryForm.residentialAddress?.streetNo}
          error={beneficiaryFormError.residentialAddress?.streetNo}
          onBlur={() => clearBeneficiaryError("residentialAddress", "streetNo")}
          onChange={(e) =>
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              streetNo: e.target.value,
            })
          }
        />
        {beneficiaryForm.residentialAddress?.country === "IT" ? (
          <PredictiveSearchDS
            label={translate("beneficiaries.questions.residenceCity.label")}
            placeholder={translate("beneficiaries.questions.selectOption")}
            options={cityList}
            value={beneficiaryForm.residentialAddress?.city ?? ""}
            error={beneficiaryFormError.residentialAddress?.city}
            onBlur={() => clearBeneficiaryError("residentialAddress", "city")}
            onChange={(value) =>
              updateBeneficiaryForm("residentialAddress", {
                ...beneficiaryForm.residentialAddress,
                city: value,
              })
            }
            sortOptionsByValue={true}
          />
        ) : (
          <InputDS
            label={translate("beneficiaries.questions.residenceCity.label")}
            placeholder={translate(
              "beneficiaries.questions.residenceCity.placeHolder"
            )}
            value={beneficiaryForm.residentialAddress?.city}
            error={beneficiaryFormError.residentialAddress?.city}
            onBlur={() => clearBeneficiaryError("residentialAddress", "city")}
            onChange={(e) =>
              updateBeneficiaryForm("residentialAddress", {
                ...beneficiaryForm.residentialAddress,
                city: e.target.value,
              })
            }
            helper={
              !beneficiaryForm.residentialAddress?.country
                ? translate("beneficiaries.questions.residenceCity.helper")
                : ""
            }
            disabled={!beneficiaryForm.residentialAddress?.country}
          />
        )}
        <InputDS
          label={translate("beneficiaries.questions.residenceZipcode.label")}
          placeholder={translate(
            "beneficiaries.questions.residenceZipcode.placeholder"
          )}
          value={beneficiaryForm.residentialAddress?.zipCode}
          error={beneficiaryFormError.residentialAddress?.zipCode}
          onBlur={() => clearBeneficiaryError("residentialAddress", "zipCode")}
          onChange={(e) =>
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              zipCode: e.target.value,
            })
          }
        />
        <p className="BodyM text-[#424242]">
          {translate("beneficiaries.questions.sameResidenceAsDomicile")}
        </p>
        <div>
          <SingleSelect
            options={sameResidenceOptions}
            onValueChange={(e) => {
              updateBeneficiaryForm("domicileSameAsResidence", e);
              clearBeneficiaryError("domicileSameAsResidence");
            }}
            defaultSize="S"
            name=""
            initialValue={beneficiaryForm.domicileSameAsResidence}
          />
          {beneficiaryFormError.domicileSameAsResidence ? (
            <div className="flex flex-row gap-[4px] BodyS text-[#EA5F5F] mt-[8px]">
              <GlobalIcon
                iconName="AlertCircleIcon"
                size="XXXS"
                color="currentColor"
                className="w-[16px] h-[16px] mt-[2px]"
              />
              <span className="flex-1">
                {beneficiaryFormError.domicileSameAsResidence}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {beneficiaryForm.domicileSameAsResidence === "no" ? (
        <>
          <p className="mb-[24px] BodyM font-medium text-[#424242] mt-[48px]">
            {translate("beneficiaries.questions.domicileAddress")}
          </p>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[24px] gap-y-[48px]">
            <div className="md:col-span-2">
              <SelectDS
                label={translate(
                  "beneficiaries.questions.domicilieCountry.label"
                )}
                placeholder={translate("beneficiaries.questions.selectOption")}
                options={countryList}
                value={beneficiaryForm.domicileAddress?.country ?? ""}
                error={beneficiaryFormError.domicileAddress?.country}
                onBlur={() =>
                  clearBeneficiaryError("domicileAddress", "zipCode")
                }
                onChange={(e) =>
                  updateBeneficiaryForm("domicileAddress", {
                    ...beneficiaryForm.domicileAddress,
                    country: e.target.value,
                    city: "",
                  })
                }
              />
            </div>
            <AutocompleteGoogleDS
              domain={DOMAIN!}
              value={beneficiaryForm.domicileAddress?.streetName}
              setValue={(value: string) => {
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  streetName: value,
                });
                clearBeneficiaryError("domicileAddress", "streetName");
              }}
              onSelect={(place) => {
                const { direction } = place;
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  streetName: direction,
                });
                clearBeneficiaryError("domicileAddress", "streetName");
              }}
              label={translate("beneficiaries.questions.residenceStreet.label")}
              placeholder={translate(
                "beneficiaries.questions.residenceStreet.placeholder"
              )}
              error={beneficiaryFormError.domicileAddress?.streetName}
            />
            <InputDS
              label={translate(
                "beneficiaries.questions.residenceStreetNo.label"
              )}
              placeholder={translate(
                "beneficiaries.questions.residenceStreetNo.placeholder"
              )}
              value={beneficiaryForm.domicileAddress?.streetNo}
              error={beneficiaryFormError.domicileAddress?.streetNo}
              onBlur={() => clearBeneficiaryError("domicileAddress", "zipCode")}
              onChange={(e) =>
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  streetNo: e.target.value,
                })
              }
            />
            {beneficiaryForm.domicileAddress?.country === "IT" ? (
              <PredictiveSearchDS
                label={translate("beneficiaries.questions.residenceCity.label")}
                placeholder={translate("beneficiaries.questions.selectOption")}
                options={cityList}
                value={beneficiaryForm.domicileAddress?.city ?? ""}
                error={beneficiaryFormError.domicileAddress?.city}
                onBlur={() => clearBeneficiaryError("domicileAddress", "city")}
                onChange={(value) =>
                  updateBeneficiaryForm("domicileAddress", {
                    ...beneficiaryForm.domicileAddress,
                    city: value,
                  })
                }
                sortOptionsByValue={true}
              />
            ) : (
              <InputDS
                label={translate("beneficiaries.questions.residenceCity.label")}
                placeholder={translate(
                  "beneficiaries.questions.residenceCity.placeHolder"
                )}
                value={beneficiaryForm.domicileAddress?.city}
                error={beneficiaryFormError.domicileAddress?.city}
                onBlur={() => clearBeneficiaryError("domicileAddress", "city")}
                onChange={(e) =>
                  updateBeneficiaryForm("domicileAddress", {
                    ...beneficiaryForm.domicileAddress,
                    city: e.target.value,
                  })
                }
                helper={
                  !beneficiaryForm.domicileAddress?.country
                    ? translate("beneficiaries.questions.residenceCity.helper")
                    : ""
                }
                disabled={!beneficiaryForm.domicileAddress?.country}
              />
            )}
            <InputDS
              label={translate(
                "beneficiaries.questions.residenceZipcode.label"
              )}
              placeholder={translate(
                "beneficiaries.questions.residenceZipcode.placeholder"
              )}
              value={beneficiaryForm.domicileAddress?.zipCode}
              error={beneficiaryFormError.domicileAddress?.zipCode}
              onBlur={() => clearBeneficiaryError("domicileAddress", "zipCode")}
              onChange={(e) =>
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  zipCode: e.target.value,
                })
              }
            />
          </div>
        </>
      ) : null}
      <div className="flex flex-col">
        <p className="mb-[24px] BodyM font-medium text-[#424242] mt-[48px]">
          {translate("beneficiaries.questions.affinityRelationship")}
        </p>
        <div>
          <SelectDS
            label={translate(
              "beneficiaries.questions.affinityRelationship.label"
            )}
            placeholder={translate("beneficiaries.questions.selectOption")}
            options={affinityRelationshipOptions}
            value={beneficiaryForm.affinityRelationship ?? ""}
            onChange={(e) =>
              updateBeneficiaryForm("affinityRelationship", e.target.value)
            }
            helper={translate(
              "beneficiaries.questions.affinityRelationship.helper"
            )}
            onBlur={() => clearBeneficiaryError("affinityRelationship")}
            error={beneficiaryFormError.affinityRelationship}
          />
        </div>
        {beneficiaryForm.affinityRelationship === "Altro" && (
          <div className="flex flex-row mt-2 items-center gap-[8px]">
            <div className="flex-1">
              <InputDS
                label={translate(
                  "beneficiaries.questions.otherAffinityRelationship.label"
                )}
                placeholder={translate(
                  "beneficiaries.questions.otherAffinityRelationship.placeholder"
                )}
                value={beneficiaryForm.otherAffinityRelationship}
                onChange={(e) =>
                  updateBeneficiaryForm(
                    "otherAffinityRelationship",
                    e.target.value
                  )
                }
                helper={translate(
                  "beneficiaries.questions.otherAffinityRelationship.helper"
                )}
                onBlur={() =>
                  clearBeneficiaryError("otherAffinityRelationship")
                }
                error={beneficiaryFormError.otherAffinityRelationship}
              />
            </div>

            <div className="flex flex-col gap-[8px]">
              <ButtonDS
                label="Carica identificazione"
                leftIcon="UploadIcon"
                className="mt-[30px]"
                buttonType="secondary"
                onClick={() => {
                  setIdentificationModal(true);
                  clearBeneficiaryError("beneficiaryDocumentation");
                }}
                size="40"
              />
              {beneficiaryFormError.beneficiaryDocumentation ? (
                <div className="flex flex-row gap-[4px] BodyS text-[#EA5F5F] mt-[8px]">
                  <GlobalIcon
                    iconName="AlertCircleIcon"
                    size="XXXS"
                    color="currentColor"
                    className="w-[16px] h-[16px] mt-[2px]"
                  />
                  <span className="flex-1">
                    {beneficiaryFormError.beneficiaryDocumentation}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-[4px]">
        <p className="mb-[24px] BodyM font-medium text-[#424242] mt-[48px]">
          {translate("beneficiaries.questions.pep.title")}
        </p>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[24px] gap-y-[48px]">
          <p className="BodyM text-[#424242]">
            {translate("beneficiaries.questions.pep.label")}
          </p>
          <SingleSelect
            options={sameResidenceOptions}
            onValueChange={(e) => {
              updateBeneficiaryForm("pepIndicator", e);
              clearBeneficiaryError("pepIndicator");
            }}
            defaultSize="S"
            name=""
            initialValue={beneficiaryForm.pepIndicator}
          />
        </div>
        {beneficiaryFormError.pepIndicator ? (
          <div className="flex flex-row gap-[4px] BodyS text-[#EA5F5F] mt-[8px]">
            <GlobalIcon
              iconName="AlertCircleIcon"
              size="XXXS"
              color="currentColor"
              className="w-[16px] h-[16px] mt-[2px]"
            />
            <span className="flex-1">{beneficiaryFormError.pepIndicator}</span>
          </div>
        ) : (
          <div className="inputDS-messages BodyS">
            <span className="inputDS-message">
              <GlobalIcon
                iconName="AlertCircleIcon"
                size="XXS"
                color="currentColor"
              />
              <span
                className="flex-1"
                dangerouslySetInnerHTML={{
                  __html: translate("beneficiaries.questions.pep.helper"),
                }}
              />
            </span>
          </div>
        )}
        {beneficiaryForm.pepIndicator === "yes" && (
          <div className="flex flex-col mt-[36px] items-center gap-y-[48px]">
            <div className="w-full">
              <SelectDS
                label={translate("beneficiaries.questions.peptype.label")}
                placeholder={translate("beneficiaries.questions.selectOption")}
                options={pepTypeList}
                value={beneficiaryForm.pepType || ""}
                onChange={(e) =>
                  updateBeneficiaryForm("pepType", e.target.value)
                }
                onBlur={() => clearBeneficiaryError("pepType")}
                error={beneficiaryFormError.pepType}
                helper="Carica in vigore o cessata da meno di un anno"
              />
            </div>
            <div className="w-full">
              <SelectDS
                label={translate("beneficiaries.questions.pepRelation.label")}
                placeholder={translate("beneficiaries.questions.selectOption")}
                options={pepRelationList}
                value={beneficiaryForm.pepRelation || ""}
                onChange={(e) =>
                  updateBeneficiaryForm("pepRelation", e.target.value)
                }
                onBlur={() => clearBeneficiaryError("pepRelation")}
                error={beneficiaryFormError.pepRelation}
              />
            </div>
          </div>
        )}
      </div>
      <IdentificationModal
        open={identificationModal}
        onClose={() => setIdentificationModal(false)}
      />
    </div>
  );
};

export default BeneficiaryForm;
