import { ButtonDS, Chip, GlobalIcon } from "get-life-storybook-ts";
import { DataI, PriceDashboardI } from "../../../../../api/request/Lead/Interfaces/DataInterface.response";
import { ProductI } from "../../../../../api/request/Lead/Interfaces/ProductInterface.response";
import { Fragment, useContext, useRef, useState } from "react";
import { I18nContext } from "../../../../../context/i18n.context";
import numberFormat from "../../../../../utils/Utils";
import ConditionsModalAB from "../../../../../components/ConditionsModal/ConditionsModalAB";
import { BeneficiaryDataI } from "../../../../../api/request/Beneficiaries/Interfaces/BeneficiariesInterfaces";

interface NewGlobalPositionI {
  userData?: DataI;
  product?: ProductI;
  navigateToRoute: (route: string) => void;
  beneficiaryIT: string
  price?: PriceDashboardI;
  domain?: string;
  resumeBeneficiaryIT?: BeneficiaryDataI[];
}
const NewGlobalPosition = ({
  userData,
  product,
  navigateToRoute,
  price,
  beneficiaryIT,
  domain,
  resumeBeneficiaryIT
}: NewGlobalPositionI) => {
  const [displayBeneficiaries, setDisplayBeneficiaries] = useState(false);
  const [displayInsured, setDisplayInsured] = useState(false);
  const [displayTaker, setDisplayTaker] = useState(false);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const lead = userData?.lead;

  const coverages = {
    basic: translate("newDashboard.coverage.basic"),
    premium: translate("newDashboard.coverage.premium"),
    eg: translate("newDashboard.coverage.eg"),
  };

  const hasBeneficiaries = domain === "it" ? beneficiaryIT : lead?.beneficiaries;

  const toggleBeneficiaries = () =>
    setDisplayBeneficiaries(!displayBeneficiaries);
  const toggleInsured = () => setDisplayInsured(!displayInsured);
  const toggleTaker = () => setDisplayTaker(!displayTaker);

  const couponInfo =
    product && product.promotion
      ? {
        conditions: product?.promotion?.conditions,
        exceptions: product?.promotion?.exceptions,
        code: product?.promotion?.promotionalCode,
      }
      : undefined;

  const nodeConditionsModal: any = useRef(null);

  const handleConditions = () =>
    nodeConditionsModal.current?.handleShowPortal();

  const beneficiariesLabel: { [key: string]: string } = {
    "Herederos Legales": translate("newDashboard.legalHeirs"),
    "Orden de Prelación": translate("newDashboard.prelationOrder"),
    other: translate("newDashboard.freeDesignation"),
    "Legal heirs": translate("newDashboard.legalHeirs"),
    "My children": translate("newDashboard.myChildren"),
    "Testament heirs": translate("newDashboard.testamentHeirs"),
    "My spouse": translate("newDashboard.mySpouse"),
    "Free designation": translate("newDashboard.freeDesignation"),
  };

  const frequency =
    userData?.lead && userData?.feeCollection?.length > 0
      ? userData?.feeCollection[0].frequency :
      userData?.lead.purchasePeriod ? userData?.lead.purchasePeriod.toUpperCase()
      : "MONTHLY";

  return (
    <>
      <div className="grid md:grid-cols-2 gap-[32px]">
        <div className="dashboard-card flex flex-col">
          <div className="text-[var(--dark-gray)] flex flex-row items-center gap-[8px] flex-wrap pb-[16px] border-b-[var(--light-gray)] border-b">
            <GlobalIcon
              iconName="ShieldCheckIcon"
              size="XS"
              color="currentColor"
            />
            <span className="flex-1 BodyL font-bold">
              {translate("newDashboard.globalPosition.protection")}
            </span>
            <ButtonDS
              label={translate("newDashboard.viewMore")}
              buttonType="tertiary"
              size="32"
              onClick={() => navigateToRoute("policy")}
            />
          </div>
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col gap-[4px]">
            <span className="BodyM text-[#7B7B7B] font-medium">
              {translate("newDashboard.assuredCapital")}
            </span>
            {lead?.capital ? (
              <Chip
                text={numberFormat(lead.capital as number, "capital")}
                icon={null}
                size="big"
              />
            ) : null}
          </div>
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col gap-[4px]">
            <span className="BodyM text-[#7B7B7B] font-medium">
              {translate("newDashboard.guarantee")}
            </span>
            {product?.traits && product?.traits.length > 0 ? (
              <div className="flex flex-col gap-[12px]">
                {product?.traits.map((t) => {
                  const { capital, trait } = t;
                  return (
                    <Chip
                      text={`${translate(
                        `newDashboard.${trait}`
                      )} ${numberFormat(capital, "capital")}`}
                      icon={null}
                      size="big"
                    />
                  );
                })}
              </div>
            ) : null}
            {product?.coverage ? (
              <Chip
                text={coverages[product.coverage]}
                icon={null}
                size="big"
                className="[&>span]:!whitespace-normal [&>span]:!break-normal rounded-[25px]"
              />
            ) : null}
          </div>
          <div className="py-[16px] border-b-[var(--light-gray)] border-b">
            <div className="flex flex-row">
              <div className="flex flex-col gap-[4px] flex-1">
                <span className="BodyM text-[#7B7B7B] font-medium">
                  {translate("newDashboard.beneficiaries")}
                </span>
                {hasBeneficiaries ? (
                  <Chip
                    text={beneficiariesLabel[hasBeneficiaries]}
                    icon={null}
                    size="big"
                    className="[&>span]:!whitespace-normal [&>span]:!break-normal rounded-[25px]"
                  />
                ) : null}
              </div>
              {(product?.beneficiariesList &&
                product?.beneficiariesList.length > 0) || (resumeBeneficiaryIT && resumeBeneficiaryIT.length > 0) ? (
                <button onClick={toggleBeneficiaries}>
                  <span
                    className="block transition-all"
                    style={{
                      transform: `rotate(${displayBeneficiaries ? 180 : 0}deg)`,
                    }}
                  >
                    <GlobalIcon
                      iconName="ChevronDown"
                      size="XS"
                      color="var(--dark-gray)"
                    />
                  </span>
                </button>
              ) : null}
            </div>
            <div
              className="pl-[24px] transition-all overflow-hidden"
              style={{ maxHeight: displayBeneficiaries ? 600 : 0 }}
            >
              {product?.beneficiariesList
                ? product.beneficiariesList.map((beneficiary, idx) => {
                  const { Nombre } = beneficiary;
                  return (
                    <Fragment key={`${Nombre}${idx}`}>
                      <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                        {translate("newDashboard.menu.profile.name")}
                      </span>
                      <span className="BodyM font-medium text-[var(--dark-gray)]">
                        {Nombre}
                      </span>
                    </Fragment>
                  );
                })
                : null}

              {resumeBeneficiaryIT
                ? resumeBeneficiaryIT.map((beneficiary, idx) => {
                  const { fullName, percentage, capital } = beneficiary;
                  return (
                    <Fragment key={`${fullName}${idx}`}>
                      <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                        {translate("dashboard.policy.beneficiariesTitle")} {idx + 1}
                      </span>
                      <span className="BodyM font-medium text-[var(--dark-gray)]">
                        {fullName}
                      </span>
                      <br></br>
                      <span className="BodyM font-bold font-medium text-[#003399]">
                        {percentage}% = {numberFormat(capital, "capital")}
                      </span>
                    </Fragment>
                  );
                })
                : null}
            </div>

          </div>
          <div className="pt-[16px] flex flex-row flex-wrap gap-[8px]">
            <p className="flex-1">
              <span className="BodyM text-[#7B7B7B] font-medium">
                {translate(
                  frequency === "MONTHLY"
                    ? "newDashboard.monthlyFee"
                    : "newDashboard.yearlyFee"
                )}
              </span>
              <br />
              {price?.promotedPrice !== price?.price ? (
                <>
                  <span className="BodyL text-[var(--dark-gray)] font-medium line-through">
                    {numberFormat(
                      price?.price!,
                      "price"
                    )}
                  </span>
                  {"  "}
                  <span className="BodyL text-[var(--dark-gray)] font-medium">
                    {numberFormat(
                      price?.promotedPrice!,
                      "price"
                    )}
                  </span>
                </>
              ) : (
                <span className="BodyL text-[var(--dark-gray)] font-medium">
                  {numberFormat(
                    price?.price!,
                    "price"
                  )}
                </span>
              )}
            </p>
            {product?.promotion ? (
              <ButtonDS
                label={translate("newDashboard.promotion")}
                buttonType="tertiary"
                ghost={true}
                size="32"
                leftIcon="Discount2Icon"
                onClick={handleConditions}
              />
            ) : null}
          </div>
          {/* <div className="mt-[16px] pb-[16px] BodyS font-medium text-[#7B7B7B] border-b-[var(--light-gray)] border-b">
            {"Próximo pago el {date}"}
          </div> */}
        </div>
        <div className="dashboard-card flex flex-col">
          <div className="text-[var(--dark-gray)] flex flex-row items-center gap-[8px] flex-wrap pb-[16px] border-b-[var(--light-gray)] border-b">
            <GlobalIcon
              iconName="UserCheckIcon"
              size="XS"
              color="currentColor"
            />
            <span className="flex-1 BodyL font-bold">
              {translate("newDashboard.menu.profile.title")}
            </span>
            <ButtonDS
              label={translate("newDashboard.viewMore")}
              buttonType="tertiary"
              size="32"
              onClick={() => navigateToRoute("profile")}
            />
          </div>
          <div className="py-[16px] border-b-[var(--light-gray)] border-b">
            <div className="flex flex-row">
              <div className="flex flex-col gap-[4px] flex-1">
                <span className="BodyM text-[#7B7B7B] font-medium">
                  {translate("newDashboard.insured")}
                </span>
                <span className="BodyL font-medium text-[var(--dark-gray)]">
                  {`${lead?.name} ${lead?.lastName ?? ""} ${lead?.lastName2 ?? ""
                    }`}
                </span>
              </div>
              <button onClick={toggleInsured}>
                <span
                  className="block transition-all"
                  style={{
                    transform: `rotate(${displayInsured ? 180 : 0}deg)`,
                  }}
                >
                  <GlobalIcon
                    iconName="ChevronDown"
                    size="XS"
                    color="var(--dark-gray)"
                  />
                </span>
              </button>
            </div>
            <div
              className="pl-[24px] transition-all overflow-hidden"
              style={{ maxHeight: displayInsured ? 600 : 0 }}
            >
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.idCard")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)]">
                {lead?.idNumber}
              </span>
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.email")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)] break-words">
                {lead?.email}
              </span>
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.address")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)]">
                {lead?.addressLine}
              </span>
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.phone")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)]">
                {lead?.phone}
              </span>
            </div>
          </div>
          <div className="pt-[16px]">
            <div className="flex flex-row">
              <div className="flex flex-col gap-[4px] flex-1">
                <span className="BodyM text-[#7B7B7B] font-medium">
                  {translate("newDashboard.taker")}
                </span>
                <span className="BodyL font-medium text-[var(--dark-gray)]">
                  {`${lead?.name} ${lead?.lastName ?? ""} ${lead?.lastName2 ?? ""
                    }`}
                </span>
              </div>
              <button onClick={toggleTaker}>
                <span
                  className="block transition-all"
                  style={{ transform: `rotate(${displayTaker ? 180 : 0}deg)` }}
                >
                  <GlobalIcon
                    iconName="ChevronDown"
                    size="XS"
                    color="var(--dark-gray)"
                  />
                </span>
              </button>
            </div>
            <div
              className="pl-[24px] transition-all overflow-hidden"
              style={{ maxHeight: displayTaker ? 600 : 0 }}
            >
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.idCard")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)]">
                {lead?.idNumber}
              </span>
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.email")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)] break-words">
                {lead?.email}
              </span>
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.address")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)]">
                {lead?.addressLine}
              </span>
              <span className="BodyS font-medium text-[#7B7B7B] mt-[16px] block">
                {translate("newDashboard.phone")}
              </span>
              <span className="BodyM font-medium text-[var(--dark-gray)]">
                {lead?.phone}
              </span>
            </div>
          </div>
        </div>
      </div>
      {product?.promotion && (
        <ConditionsModalAB
          couponInfo={couponInfo}
          nodeFakeDoorModal={nodeConditionsModal}
          promotion={product.promotion}
          collection={userData!.feeCollection}
          period={(product.period?.toLowerCase() || "monthly") as any}
          handleSubmit={() => nodeConditionsModal.current?.handleShowPortal()}
        />
      )}
    </>
  );
};

export default NewGlobalPosition;
