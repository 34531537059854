import {
  ErrorResponse,
  ValidationsI,
  isEmail,
  isITPhone,
  isPhone,
} from "get-life-storybook-ts/lib/components/Validations";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import HttpLeadRepository from "../api/request/Lead/Lead.service";
import {
  validateActuarialAge,
  validatePhoneFR,
  validateZipCodeFR,
  validationsGlobal,
} from "../utils/validations";
import { GetlifeContext, LanguageT } from "./GetLifeContext.context";
import { GetLifeTrackerContext } from "./GetLifeTracker.context";
import { I18nContext } from "./i18n.context";
import { MULTILANG } from "../utils/InternationlChanges";
import {
  OrganicRequestI,
  OrganicResponseI,
} from "../api/request/Lead/Interfaces/OrganicInterface";
import { reorderDate } from "get-life-storybook-ts";

interface DisabledButtonsI {
  capital: boolean;
  dateOfBirth: boolean;
  zipCode: boolean;
  email: boolean;
  phone: boolean;
}

interface GetLifeCalculatorContextI {
  basicFrom: number;
  calculatorLocation: string;
  checkedConditions: boolean;
  checkedConditions2: boolean;
  checkedConditions3: boolean;
  checkedConditions4: boolean;
  coverage: string;
  dateOfBirth: string;
  disabledButtons: DisabledButtonsI;
  disabledNull: (field: any) => boolean;
  domain: LanguageT;
  email: string;
  error: ErrorResponse | undefined;
  errorEmail: ErrorResponse | undefined;
  errorBirthDate: ErrorResponse | undefined;
  gtf_campaign: string | null;
  handleCallMe: () => void;
  handleCheckConditions: (value: boolean) => void;
  handleCheckConditions2: (value: boolean) => void;
  handleCheckConditions3: (value: boolean) => void;
  handleCheckConditions4: (value: boolean) => void;
  handleClickOrganic: (dataRequest: any) => void;
  handleClickPersonalData: () => void;
  insuranceCover: string;
  insuranceType: boolean;
  job: string;
  leadRepository: any;
  maxCapital: number;
  minCapital: number;
  navigateBack: () => void;
  navigateTo: () => void;
  phoneNumber: string;
  postcode: string;
  premiumFrom: number;
  pricePromotion: number | undefined;
  professions?: any;
  setBasicFrom: (value: number) => void;
  setCoverage: (value: string) => void;
  setDateOfBirth: (value: string) => void;
  setEmail: (value: string) => void;
  setInsuranceCover: (value: string) => void;
  setInsuranceType: (value: boolean) => void;
  setJob: (value: string) => void;
  setLoading: (value: boolean) => void;
  setMaxCapital: (value: number) => void;
  setMinCapital: (value: number) => void;
  setPhoneNumber: (value: string) => void;
  setPostcode: (value: string) => void;
  setPremiumFrom: (value: number) => void;
  setPricePromotion: (value: number) => void;
  setSex: (value: string) => void;
  setSmoke: (value: string) => void;
  setUrl: (value: string) => void;
  setWorkDistance: (value: boolean) => void;
  setWorkHeight: (value: boolean) => void;
  setWorkWeight: (value: boolean) => void;
  sex: string;
  smoke: string;
  stepCalculator: string | undefined;
  translate: (key: string) => string;
  url: string;
  validations: ValidationsI;
  workDistance: boolean;
  workHeight: boolean;
  workWeight: boolean;
  intention: string;
  downloadableLoading: boolean;
  RegeneratePhoneValidationCode: () => Promise<void>;
  ValidatePhoneValidationCode: (code: string) => any;
  phoneValidationModal: boolean;
  setPhoneValidationModal: (value: boolean) => void;
  ucc: string | null;
}

export const GetLifeCalculatorContext = React.createContext(
  {} as GetLifeCalculatorContextI
);

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

const EXACTLY = 5;

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const GetlifeCalculatorProvider = ({ children }: any) => {
  const { stepCalculator } = useParams();

  const navigate = useNavigate();

  const {
    brokerId,
    domain,
    handleCallMe,
    init,
    leadId,
    token,
    setIsABtesting,
    setLeadId,
    setLoading,
    setProgressBar,
    setTokenVelogica,
    gtf_campaign,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    ucc,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
  } = useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { handleTrackerQuestion, identifyFS } = React.useContext(
    GetLifeTrackerContext
  );

  let query = useQuery();

  const [basicFrom, setBasicFrom] = useState<number>(0);
  const [checkCache, setCheckCache] = useState<boolean>(false);
  const [checkedConditions, setCheckedConditions] = useState<boolean>(
    checkCache === true ? true : false
  );
  const [checkCache2, setCheckCache2] = useState<boolean>(false);
  const [checkCache3, setCheckCache3] = useState<boolean>(false);
  const [checkCache4, setCheckCache4] = useState<boolean>(false);
  const [checkedConditions2, setCheckedConditions2] = useState<boolean>(
    checkCache2 === true ? true : false
  );
  const [checkedConditions3, setCheckedConditions3] = useState<boolean>(
    checkCache3 === true ? true : false
  );
  const [checkedConditions4, setCheckedConditions4] = useState<boolean>(
    checkCache4 === true ? true : false
  );
  const [coverage, setCoverage] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [disabledButtons, setDisabledButtons] = useState<any>({
    capital: true,
    dateOfBirth: true,
    email: true,
    phone: true,
    zipCode: true,
  });
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<ErrorResponse>();
  const [errorEmail, setErrorEmail] = useState<ErrorResponse>();
  const [errorBirthDate, setErrorBirthDate] = useState<ErrorResponse>();
  const [first, setFirst] = useState<boolean>(true);
  const [insuranceCover, setInsuranceCover] = useState<string>("");
  const [intention, setIntention] = useState<string>("");
  const [insuranceType, setInsuranceType] = useState<boolean>(
    process.env.REACT_APP_DOMAIN_LOCALE === "es" ? false : true
  );
  const [job, setJob] = useState<string>("");
  const [maxCapital, setMaxCapital] = useState<number>(0);
  const [minCapital, setMinCapital] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [premiumFrom, setPremiumFrom] = useState<number>(0);
  const [pricePromotion, setPricePromotion] = useState<any>();
  const [professions, setProfessions] = useState<any>([]);
  const [sex, setSex] = useState<string>("");
  const [smoke, setSmoke] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [workDistance, setWorkDistance] = useState<boolean>(false);
  const [workHeight, setWorkHeight] = useState<boolean>(false);
  const [workWeight, setWorkWeight] = useState<boolean>(false);
  const [downloadableLoading, setDownloadableLoading] = useState<boolean>(true);
  const [phoneValidationModal, setPhoneValidationModal] =
    useState<boolean>(false);

  const lead = query.get("lead");
  const leadRepository = new HttpLeadRepository(
    (lead as string) || leadId,
    token
  );
  const location = useLocation();
  const calculatorLocation = location?.pathname.split("/")[1];

  // const HasPhoneValidation =
  //   utm_campaign?.includes("phone_verification") &&
  //   !utm_source?.includes("feebbo");

  const HasPhoneValidation = false;

  React.useEffect(() => {
    if (stepCalculator === "1") {
      init("entry_calculator_1");
    }
  }, []);
  const disabledNull = (field: any) =>
    field === null || field === undefined || field === "";

  const validations: ValidationsI = {
    capital: [
      (value: number) =>
        validationsGlobal({
          value,
          translate,
          min: minCapital,
          max: maxCapital,
          disabledData: disabledButtons,
          field: "capital",
          setDisabled: setDisabledButtons,
        }),
    ],
    dateOfBirth: [],
    email: [
      (value: string) =>
        validationsGlobal({
          value,
          translate,
          min: minCapital,
          max: maxCapital,
          disabledData: disabledButtons,
          field: "email",
          setDisabled: setDisabledButtons,
        }),
    ],
    phone:
      DOMAIN === "es" || DOMAIN === "wl_es"
        ? [
            (value: string) =>
              validationsGlobal({
                value,
                translate,
                disabledData: disabledButtons,
                field: "phone",
                setDisabled: setDisabledButtons,
              }),
          ]
        : [],
    zipCode: [],
  };

  const navigateTo = (leadId?: string) => {
    if (DOMAIN === "fr" && stepCalculator === "8") {
      navigate("/waiting-customer");
    } else {
      let path = "";
    if (stepCalculator) {
      path += `/${calculatorLocation}/${parseInt(stepCalculator) + 1}`;
    }
    if (leadId && typeof leadId === "string") {
      path += `?lead=${leadId}`;
    }
    navigate(path);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    stepCalculator && pageViewEvent(parseInt(stepCalculator) + 1);
    }
    
  };

  const navigateBack = () => {
    stepCalculator && pageViewEvent(parseInt(stepCalculator) - 1);
    stepCalculator &&
      navigate(`/${calculatorLocation}/${parseInt(stepCalculator) - 1}`);
  };

  const handleCheckConditions = (e: boolean) => {
    setCheckedConditions(e);
    setCheckCache(e);
  };

  const handleCheckConditions2 = (e: boolean) => {
    setCheckedConditions2(e);
    setCheckCache2(e);
  };

  const handleCheckConditions3 = (e: boolean) => {
    setCheckedConditions3(e);
    setCheckCache3(e);
  };

  const handleCheckConditions4 = (e: boolean) => {
    setCheckedConditions4(e);
    setCheckCache4(e);
  };


  const getProfessions = async () => {
    let jobs: any = [];
    try {
      const response = await leadRepository.getProfessions();
      response.value.forEach((profession: any) => {
        jobs.push({
          label: profession.value,
          value: profession.id,
          text: profession.value,
        });
      });
    } catch (error) {}

    return jobs;
  };

  const pageViewEvent = (step: number) => {
    const dataInfo = {
      event: "virtualPageview",
      page: window?.location?.href || `/calculator/${step}`,
      pageTitle: `APP Calculator - Step ${step}`,
    };

    handleTrackerQuestion({ type: "GA", data: dataInfo });

    handleTrackerQuestion({
      type: "FS",
      event: `Calculator Step ${step} Viewed`,
      data: {},
    });
  };

  const parseTracker = (data: OrganicResponseI) => {
    const dataInfo = {
      page: url,
      leadId: data.leadId,
      intent: undefined,
      phone: phoneNumber,
      email: email,
      calculatedCapital: parseFloat(insuranceCover),
      calculatedPremium: data.basicPrice,
      coverage: data.coverage === "premium" ? "FIPA" : "F",
      gender: sex,
      postalCode: postcode,
      utm_campaign: utm_campaign,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_content: utm_content,
      utm_term: utm_term,
      utm_test: utm_test,
      gclid: gclid,
      msclkid: msclkid,
      fbclid: fbclid,
      platform: "App",
      entryPage: getEntryPage(),
      referrerUrl: getReferrerUrl(),
      ABTestVersion: getABTestVersion(),
    };

    identifyFS({
      lead: data.leadId,
      data: {
        emailDomain: email && email.split("@")[1],
        email: email,
        brokerId: brokerId,
      },
    });

    if (data.isFirstIncoming) {
      const event = "leadCreated";
      handleTrackerQuestion({
        type: "FS",
        event,
        data: {
          birthDate: new Date(dateOfBirth),
          ...dataInfo,
        },
      });

      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          birthDate: dateOfBirth,
          ...dataInfo,
        },
      });
    }
    const event = "calculatorQuoteGenerated";

    handleTrackerQuestion({
      type: "FS",
      event,
      data: {
        birthDate: new Date(dateOfBirth),
        ...dataInfo,
      },
    });
    handleTrackerQuestion({
      type: "GA",
      data: {
        event,
        birthDate: dateOfBirth,
        ...dataInfo,
      },
    });
    handleTrackerQuestion({
      type: "tracker",
      data: {
        leadUlid: data.leadId,
        campaign: gtf_campaign ?? "calculator-app",
      },
    });
  };

  const fetchOrganic = async (dataRequest: OrganicRequestI) => {
    try {
      const data: OrganicResponseI = await leadRepository.postLeadOrganic(
        dataRequest
      );
      setBasicFrom(data.basicPrice);
      setCoverage(data.coverage);
      setPremiumFrom(data.premiumPrice);
      setLeadId(data.leadId);
      parseTracker(data);
      navigateTo(data.leadId);
    } catch (error: any) {
      throw new Error("Error in organic");
    }
  };

  const handleClickOrganic = async (dataRequest: OrganicRequestI) => {
    if (HasPhoneValidation) {
      GeneratePhoneValidationCode();
      setPhoneValidationModal(true);
      return;
    }
    setDownloadableLoading(true);
    await fetchOrganic(dataRequest);
  };

  const handleClickPersonalData = async () => {
    setLoading(true);
    let capitals: any;
    try {
      if (["es", "wl_es"].includes(DOMAIN!)) {
        capitals = await leadRepository.getCapitalsExtended(dateOfBirth);
      } else {
        capitals = await leadRepository.getCapitals(dateOfBirth);
      }

      setMinCapital(capitals.minCapital);
      setMaxCapital(capitals.maxCapital);
      navigateTo();
    } catch (error) {}
    setLoading(false);
  };

  const calculatorBar: boolean | number =
    stepCalculator !== MULTILANG[domain].final_step &&
    parseInt(stepCalculator!) * MULTILANG[domain].progress;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (
      !dateOfBirth &&
      stepCalculator !== "1" &&
      ((domain === "it" && !checkCache && !checkCache2 && !checkCache4) ||
        (domain !== "it" && checkCache)) &&
      !lead
    ) {
      navigate(`/${calculatorLocation}/1`);
    }
    if (error !== undefined) {
      setError(undefined);
    }
    setProgressBar(calculatorBar);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (
      calculatorLocation === "calculatorChallenger" &&
      ((stepCalculator === "4" && DOMAIN === "es") ||
        (stepCalculator === "9" && DOMAIN === "fr"))
    ) {
      leadRepository.assingABTest();
      setIsABtesting(true);
    }
  }, [stepCalculator]);

  const getProfessionsRecursive = async (): Promise<void> => {
    const fetchedProfessions = await getProfessions();
    if (fetchedProfessions.length === 0) return await getProfessionsRecursive();
    setProfessions(fetchedProfessions);
  };

  const ValidatePhoneValidationCode = async (code: string) => {
    const isValid = await fetch(
      `${process.env.REACT_APP_CUSTOMER_APP_URL}api/public/lead/phone/validate`,
      {
        method: "POST",
        body: JSON.stringify({
          phone: phoneNumber,
          code: code,
        }),
      }
    );
    if (isValid.status === 200) {
      await PhoneValidationTrackingEvent({ eventName: "PhoneValidationValid" });
      setDownloadableLoading(true);
      await fetchOrganic({
        birthDate: reorderDate(dateOfBirth),
        birthdate: reorderDate(dateOfBirth),
        campaign: gtf_campaign || "calculator-app",
        capital: parseFloat(insuranceCover),
        email: email,
        gender: sex,
        initialCapital: minCapital,
        phone: phoneNumber,
        zipcode: postcode,
        nicotine: smoke,
        scorProfessionId: job,
        distanceUpper25000KmPerYear: workDistance,
        distanceUpper25numberKmPerYear: workDistance,
        heightMoreThan3m: workHeight,
        loadMoreThan5Kg: workWeight,
      } as OrganicRequestI);
      return { error: false, message: "" };
    }
    await PhoneValidationTrackingEvent({ eventName: "PhoneValidationInvalid" });
    return {
      error: true,
      message: translate("phone.validation.modal.InvalidCode"),
    };
  };

  const RegeneratePhoneValidationCode = async () => {
    await PhoneValidationTrackingEvent({
      eventName: "PhoneValidationCodeRegenerated",
    });
    await GeneratePhoneValidationCode();
  };

  const GeneratePhoneValidationCode = async () => {
    await fetch(
      `${process.env.REACT_APP_CUSTOMER_APP_URL}api/public/lead/phone/generate-code`,
      {
        method: "POST",
        body: JSON.stringify({
          phone: phoneNumber,
        }),
      }
    );
  };

  const PhoneValidationTrackingEvent = async ({ eventName }: any) => {
    const dataInfo = {
      event: eventName,
      page: url,
      leadId: null,
      phone: phoneNumber,
      email: email,
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_term: utm_term,
      utm_test: utm_test,
      gclid: gclid,
      msclkid: msclkid,
      fbclid: fbclid,
      entryPage: getEntryPage(),
      referrerUrl: getReferrerUrl(),
      ABTestVersion: getABTestVersion(),
      platform: "App",
    };

    handleTrackerQuestion({
      type: "GA",
      data: {
        birthDate: dateOfBirth,
        ...dataInfo,
      },
    });

    handleTrackerQuestion({
      type: "FS",
      event: eventName,
      data: {
        birthDate: new Date(dateOfBirth),
        ...dataInfo,
      },
    });
  };

  useEffect(() => {
    if (location.pathname.includes("lead")) {
      init("calculator_lead_in_url", true);
      setLeadId(location.pathname.split("lead=")[1]);
    }
    if (DOMAIN === "fr") {
      (async () => {
        setTokenVelogica("");
        setLoading(true);
        await getProfessionsRecursive();
        setLoading(false);
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (DOMAIN === "fr" && phoneNumber.length >= 6) {
        const errorPhone = await validatePhoneFR({
          value: phoneNumber,
          errorGlobal: error as ErrorResponse,
          translate,
        });
        setError(errorPhone);
        setDisabledButtons({ ...disabledButtons, phone: errorPhone.error });
      }
      if (DOMAIN === "it" && phoneNumber.length >= 6) {
        const errorPhone = isITPhone({
          value: phoneNumber,
          message: translate("error.notPhone"),
        });
        setError(errorPhone);
        setDisabledButtons({ ...disabledButtons, phone: errorPhone.error });
      }

      if (
        (DOMAIN === "es" || DOMAIN === "wl_es" || DOMAIN === "sql_es") &&
        phoneNumber.length >= 6
      ) {
        const errorPhone = isPhone({
          value: phoneNumber,
          message: translate("error.notPhone"),
        });
        setError(errorPhone);
        setDisabledButtons({ ...disabledButtons, phone: errorPhone.error });
      }
    })();
  }, [phoneNumber]);

  useEffect(() => {
    (async () => {
      if (email.length > 4) {
        const errorEmail = isEmail({
          value: email,
          message: translate("questions.email.error"),
        });
        setErrorEmail(errorEmail);
        setDisabledButtons({ ...disabledButtons, email: errorEmail.error });
      }
    })();
  }, [email]);

  useEffect(() => {
    (async () => {
      if (DOMAIN === "fr" && postcode.length >= 5) {
        const errorZipCode = await validateZipCodeFR({
          value: postcode,
          errorGlobal: error as ErrorResponse,
          translate,
        });
        setError(errorZipCode);
        setDisabledButtons({ ...disabledButtons, zipCode: errorZipCode.error });
      } else if (postcode.length >= 5) {
        const errorZipCode = validationsGlobal({
          value: postcode,
          translate,
          exactly: EXACTLY,
          disabledData: disabledButtons,
          field: "zipCode",
          setDisabled: setDisabledButtons,
        });
        setError(errorZipCode);
        setDisabledButtons({ ...disabledButtons, zipCode: errorZipCode.error });
      } else if (!first) {
        const errorZipCode = validationsGlobal({
          value: postcode,
          translate,
          exactly: EXACTLY,
          disabledData: disabledButtons,
          field: "zipCode",
          setDisabled: setDisabledButtons,
        });
        setError(errorZipCode);
        setDisabledButtons({ ...disabledButtons, zipCode: errorZipCode.error });
      }
      setFirst(false);
    })();
  }, [postcode]);

  useEffect(() => {
    if (dateOfBirth.length === 10) {
      (async () => {
        const errorDateOfBirth = await validateActuarialAge({
          value: dateOfBirth,
          errorGlobal: error as ErrorResponse,
          translate,
        });
        setErrorBirthDate(errorDateOfBirth);
        setDisabledButtons({
          ...disabledButtons,
          dateOfBirth: errorDateOfBirth.error,
        });
      })();
    }
  }, [dateOfBirth]);

  useEffect(() => {
    if (insuranceCover) {
      validationsGlobal({
        value: Number(insuranceCover),
        translate,
        min: minCapital,
        max: maxCapital,
        disabledData: disabledButtons,
        setDisabled: setDisabledButtons,
        field: "capital",
      });
    }
  }, [insuranceCover]);

  const values = {
    basicFrom,
    calculatorLocation,
    checkedConditions,
    checkedConditions2,
    checkedConditions3,
    checkedConditions4,
    coverage,
    dateOfBirth,
    disabledButtons,
    disabledNull,
    domain,
    email,
    error,
    errorEmail,
    errorBirthDate,
    gtf_campaign,
    handleCallMe,
    handleCheckConditions,
    handleCheckConditions2,
    handleCheckConditions3,
    handleCheckConditions4,
    handleClickOrganic,
    handleClickPersonalData,
    insuranceCover,
    insuranceType,
    job,
    leadRepository,
    maxCapital,
    minCapital,
    navigateBack,
    navigateTo,
    phoneNumber,
    postcode,
    premiumFrom,
    pricePromotion,
    professions,
    setBasicFrom,
    setCheckCache,
    setCheckCache2,
    setCoverage,
    setDateOfBirth,
    setEmail,
    setInsuranceCover,
    setInsuranceType,
    setJob,
    setLoading,
    setMaxCapital,
    setMinCapital,
    setPhoneNumber,
    setPostcode,
    setPremiumFrom,
    setPricePromotion,
    setSex,
    setSmoke,
    setUrl,
    setWorkDistance,
    setWorkHeight,
    setWorkWeight,
    sex,
    smoke,
    stepCalculator,
    translate,
    url,
    validations,
    workDistance,
    workHeight,
    workWeight,
    intention,
    downloadableLoading,
    RegeneratePhoneValidationCode,
    ValidatePhoneValidationCode,
    phoneValidationModal,
    setPhoneValidationModal,
    ucc,
  };
  return (
    <GetLifeCalculatorContext.Provider value={values}>
      {children}
    </GetLifeCalculatorContext.Provider>
  );
};
